import React, { useContext, useEffect } from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import ScrollableAnchor, { goToAnchor } from 'react-scrollable-anchor';
import BrandCTA from 'components/BrandCta';
import { appContext } from 'providers/appProvider';
import { Layout, Seo } from 'components';
import ContentBlock from 'components/Content/ContentBlock';
import ColorBlock from 'components/Content/ColorBlock';
import TableBlock from 'components/Content/TableBlock';
import SourceEmitter from 'libs/emitter';

// import page style
import './b12-deficiency.scss';

import imgOneInSix from '../../../assets/images/b12-deficiency-prevalence.png';

import b12DefficiencyHCPHeroMobile from '../../../assets/images/NS-HCP-Hero-Vitb12Deficiency-mobile.png';

const tableData = [
  [
    'FDA-approved',
    'Prescription',
    'Once-a-week dosing',
    'Odorless',
    'Tasteless',
    'Can be taken at home',
    'Not an injection',
  ],
  ['Once-a-day dosing', 'Can be taken at home', 'Not an injection'],
  [
    'FDA-approved',
    'Prescription',
    'Daily to monthly dosing',
    'Odorless',
    'Tasteless',
  ],
];

const pageTitle = 'NASCOBAL® | What Is Vitamin B12 Deficiency?';
const pageDescription = 'Find common risk factors that may affect patients';

const B12deficiencyPage = () => {
  let Subscription = null;
  const { appConfigs } = useContext(appContext);
  const indication = appConfigs?.siteOptions?.INDICATIONS?.hcp;
  const metaData = appConfigs?.metaData?.hcp;

  const brandNames = {
    NASCOBAL: appConfigs?.metaData?.brandNames?.nascobal,
    BARIACTIV: appConfigs?.metaData?.brandNames?.bariactiv,
    NUTRITION: appConfigs?.metaData?.brandNames?.nutrition,
  };

  const triggerHashChange = () => {
    const currentHash = window?.location?.hash?.substring(
      1,
      window?.location?.hash?.length,
    );
    SourceEmitter.emit('HashChangedEventTrigger', currentHash);
  };

  useEffect(() => {
    window.addEventListener('hashchange', triggerHashChange, false);
    // Triggered from Logo Component
    Subscription = SourceEmitter.addListener('gotoTopTriggerEvent', data => {
      goToAnchor('top');
    });
    return () => {
      window.removeEventListener('hashchange', triggerHashChange, false);
      Subscription && Subscription.remove();
    };
  }, []);

  const renderPage = () => {
    return (
      <div id="nascobal-hcp-b12deficiency">
        <ContentBlock id="b12deficiency-block">
          <Grid
            fluid
            style={{
              paddingLeft: 0,
              paddingRight: 0,
              paddingTop: 0,
            }}
          >
            <Row>
              <Col xs={12}>
                <div id="hero" className="hide-in-small">
                  <h2>
                    Vitamin B<sub className="vitaminb12-sub">12</sub>{' '}
                    <br className="hide-only-mobile" />
                    deficiency
                  </h2>
                </div>
                <div id="hero-mobile" className="show-in-small">
                  <img src={b12DefficiencyHCPHeroMobile} />
                </div>
              </Col>
            </Row>
            <ColorBlock white>
              <Row>
                <Col xs={12} md={12}>
                  {/* <HeadlineBar id="b12options-headliner" blue>
                  WHAT IS VITAMIN B<sub className="vitaminb12-sub">12</sub>{' '}
                  <span className="yellow">DEFICIENCY</span>
                </HeadlineBar> */}
                  <h2 className="strong">
                    Risk factors that may affect your patients
                  </h2>
                  <p>
                    If your patients present with the following conditions or
                    characteristics, they be at higher risk of developing
                    vitamin B<sub className="vitaminb12-sub">12</sub> deficiency
                    <sup>1</sup>
                  </p>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6}>
                  <ul className="first-list">
                    <li>
                      Celiac disease<sup>2,3</sup>
                    </li>
                    <li>Alcohol abuse</li>
                    <li>Strict vegetarian or vegan diet</li>
                    <li>
                      Long-term use of certain medications such as metformin and
                      proton pump inhibitors
                    </li>
                    <li>
                      Immune system disorders<sup>4</sup>
                    </li>
                    <li>
                      HIV infection<sup>4</sup>
                    </li>
                    <li>Crohn's disease</li>
                    <li>Atrophic gastritis</li>
                    <li>Gastric surgery</li>
                    <li>
                      Hypothyroidism<sup>5</sup>
                    </li>
                  </ul>
                </Col>
                <Col xs={12} md={6}>
                  <div style={{ textAlign: 'center' }}>
                    <img src={imgOneInSix} alt="b12-deficiency-prevalence" />
                  </div>
                </Col>
              </Row>
            </ColorBlock>
            <ColorBlock white id="colorblock-patient-resources">
              <Row>
                <Col xs={12}>
                  <h2 className="strong">
                    Vitamin B<sub className="vitaminb12-sub">12</sub> options
                  </h2>
                  <Row>
                    <Col xs={12} md={4}>
                      <TableBlock
                        headBgColor="#fbaf40"
                        dataList={tableData[0]}
                        tableTitle={brandNames.NASCOBAL + '<br/>Nasal Spray'}
                      />
                    </Col>
                    <Col xs={12} md={4}>
                      <TableBlock
                        headBgColor="#e6f2fb"
                        dataList={tableData[1]}
                        tableTitle={'SUBLINGUAL<br/>(under-the-tongue)'}
                      />
                    </Col>
                    <Col xs={12} md={4}>
                      <TableBlock
                        headBgColor="#f3f3f3"
                        dataList={tableData[2]}
                        tableTitle={'INJECTIONS<br/>&nbsp;'}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </ColorBlock>
            <Row>
              <Col xs={12} md={6}>
                <BrandCTA
                  href="/vitaminb12/hcp/about"
                  LinkCaption="SEE HOW TO USE NASCOBAL"
                >
                  SEE HOW NASCOBAL<sup className="brand-reg-mark">&reg;</sup>{' '}
                  MAY HELP
                </BrandCTA>
              </Col>
              <Col xs={12} md={6}>
                <BrandCTA
                  href="/vitaminb12/hcp/request_a_sales_rep"
                  LinkCaption="REQUEST A REPRESENTATIVE"
                >
                  REQUEST A REPRESENTATIVE
                </BrandCTA>
              </Col>
            </Row>
          </Grid>
        </ContentBlock>
        <ScrollableAnchor id="isi_anchor">
          <div />
        </ScrollableAnchor>
      </div>
    );
  };

  return (
    <Layout indication={indication} className="hcp-b12deficiency-page">
      <Seo pageTitle={pageTitle} pageDescription={pageDescription} />
      <div className="body-content-container">
        <div className="body-content gutter-all">{renderPage()}</div>
      </div>
    </Layout>
  );
};

export default B12deficiencyPage;
